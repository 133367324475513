import React, { useState, useEffect } from 'react'
import axios from 'axios'
import socketIOClient from 'socket.io-client'
import Modal from 'react-modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

Modal.setAppElement('#root')

const UploadFile = () => {

  const [isModalOpen, setIsModalOpen] = useState(false)

  const [payload, setPayload] = useState('')
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const [errorResponse, setErrorResponse] = useState('')
  const [crmPhoneTest, setCrmPhoneTest] = useState('')

  const [file, setFile] = useState(null)
  const [codWeb, setCodweb] = useState(null)
  const [uploadStatus, setUploadStatus] = useState('')

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0]
    setFile(selectedFile)
  }

  const handleUpload = async () => {
    if (!file) {
      alert('Please select a file.')
      return
    }

    const formData = new FormData()
    formData.append('crm', file)
    // formData.append('companyId', process.env.REACT_APP_COMPANY_ID)
    formData.append('companyId', codWeb)

    try {
      const response = await axios.post(`${process.env.REACT_APP_URL_API}/api/v1/crm/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer 2ivck10D3o9qAZi0pkKudVDl9bdEVXY2s8gdxZ0jYgL1DZWTgDz6wDiIjlWgYmJtVOoqf0b42ZTLBRrfo8WoAaScRsujz3jQUNXdchSg0o43YilZGmVhheGJNAeIQRknHEll4nRJ7avcFgmDGoYbEey7TSC8EHS4Z3gzeufYYSfnKNDBwwzBURIQrTOxYFe3tBHsGOzwnuD2lU5tnEx7tr2XRO4zRNYeNY4lMBOFM0mRuyAe4kuqTrKXmJ8As200'
        },
      })

      if (response.status === 200) {

        const { url } = response.data

        console.log('-----> url: ', url)
        if (url)
          window.location.href = url

        setUploadStatus('File uploaded successfully!')
      } else {
        setUploadStatus('Failed to upload file.')

        console.log('error')
      }
    } catch (error) {
      console.error('Error uploading file:', error)
      setUploadStatus('Error uploading file.')

      setStatus('error')
      console.log('error: ', error)
      setErrorResponse(error.response.data.msg)
    }
  }




  useEffect(() => {

    // console.log('process.env.REACT_APP_COMPANY_ID: ', process.env.REACT_APP_COMPANY_ID)

    const currentURL = window.location.href
    const urlObject = new URL(currentURL)
    const clientId = urlObject.searchParams.get('clientId')
    const codWebFromUrl = urlObject.searchParams.get('codWeb')
    if (codWebFromUrl)
      setCodweb(codWebFromUrl)

    console.log('clientId: ', clientId)
    console.log('codWebFromURl: ', codWebFromUrl)
    console.log('codWeb: ', codWeb)

    if (clientId && codWebFromUrl) {

      const postData = async () => {
        try {
          await axios.post(`${process.env.REACT_APP_URL_API}/api/v1/crm/test`, {
            clientId,
            companyId: codWebFromUrl,
          })
        } catch (error) {
          setStatus('error')
          console.log('-----> Error:', error)
          console.log('TEST: ', error.response.data.msg)
          setErrorResponse(error.response.data.msg)
        }
      }

      postData()

    }

    const socket = socketIOClient(process.env.REACT_APP_URL_API)


    console.log('==============> codWebFromUrl: ', codWebFromUrl)

    if (codWebFromUrl)
      socket.emit('companySession', codWebFromUrl)

    socket.on('crm_upload', (data) => {
      console.log('Received data from server:', data)

      setStatus(data?.status)

      setMessage(data?.data?.msg)

      if (data?.data?.request) {
        setPayload(data.data.request, null, 2)
      }

      if (data?.data?.crmPhoneTest) {
        setCrmPhoneTest(data.data.crmPhoneTest)
      }
      setIsModalOpen(true)
    })

    return () => {
      socket.disconnect()
    }
  }, [])

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const handleAfterOpenModal = () => {
  }

  return (
    <div className="centered">
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload</button>
      {uploadStatus && <p>{uploadStatus}</p>}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleAfterOpenModal}
        contentLabel="Example Modal"
      >
        <div className="modal-title">

          {status === 'finished' && <h2>Template validado com sucesso</h2>}
          {status === 'error' && <h2>Template com erro no processo abaixo</h2>}
          {status === 'processing' && <h2>Testando template. Aguarde...</h2>}

        </div>

        <div className="error-message">
          {status === 'error' && (
            <p>
              {errorResponse}
            </p>
          )}
        </div>

        <div className="success-message">
          {status === 'finished' && (
            <p>
              Verifique no seu CRM os dados inseridos para o contato de teste: {crmPhoneTest}
            </p>
          )}
        </div>


        <div className="icon-container">
          {status === 'finished' &&
            <div className="checkmark-container">
              <FontAwesomeIcon icon={faCheckCircle} size="5x" color="green" />
            </div>
          }
        </div>

        {status === 'finished' ? <></> : <> <MessageComponent title={message} />{payload ? <PayloadComponent formattedJSON={payload} /> : <></>}</>}

        <button className="close-button" onClick={closeModal}>Close Modal</button>
      </Modal>
    </div>
  )
}

const MessageComponent = ({ title }) => {
  return (
    <div>
      <h4>{title}</h4>
    </div>
  )
}

const PayloadComponent = ({ formattedJSON }) => {
  return (
    <div>
      <pre>{JSON.stringify(formattedJSON, null, 2)}</pre>
    </div>
  )
}


export default UploadFile
